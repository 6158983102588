<template>
  <div class="top">
    <img src="@/assets/img/logo.png" alt="" />
    <div class="right">
      <div class="phone">
        <img src="@/assets/img/phone.png" alt="" width="20px" />
      </div>
      <span style="margin-left: 10px">0371-85510169</span>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
};
</script>
    
<style scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 5%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  z-index: 10;
  color: #fff;
}
.top > img {
  width: 30vw;
}
.top .right {
  display: flex;
  align-items: center;
}
.top .right img {
  width: 22px;
}
.phone {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
</style>