<template>
  <div class="home">
    <Loading class="load" v-if="$store.state.loadNum < 1"></Loading>
    <Top></Top>
    <Screen ref="cnt" class="Screen"></Screen>
    <div class="cnt-wp">
      <div class="title">
        <p>Company</p>
        <b>公司模块</b>
      </div>
      <Swiper></Swiper>
      <div class="title">
        <p>News and trends</p>
        <b>新闻动态</b>
      </div>
      <News></News>
    </div>
  </div>
</template>
    
<script>
import Top from "@/components/Phone/top.vue";
import Screen from "@/components/Phone/screen.vue";
import Swiper from "@/components/Phone/swiper.vue";
import News from "@/components/Phone/news.vue";
import Loading from "@/components/Pc/load.vue";
export default {
  name: "",
  components: {
    Top: Top,
    Screen: Screen,
    Swiper: Swiper,
    News: News,
    Loading: Loading,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
    
<style scoped>
.cnt-wp {
  position: relative;
  margin-top: -10vw;
  background: #f0f0f0;
  border-radius: 4vw 4vw 0px 0px;
  /* padding: 10vw 0vw; */
  z-index: 10;
  overflow: hidden;
  padding: 0 5%;
  box-sizing: border-box;
}
.title {
  position: relative;
  text-align: center;
  margin: 15vw auto 5vw;
  padding-bottom: 3vw;
  box-sizing: border-box;
}
.title::before {
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0%;
  bottom: 0%;
  content: "";
  background: #e1e1e1;
}

.title p {
  /* text-transform: uppercase; */
  font-size: 7vw;
  font-weight: bold;
  font-family: BrownBlod;
  position: absolute;
  width: 100%;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), #d9d9d9);
  background-clip: text;
  color: transparent;
  line-height: 1;
  top: -3vw;
}
.title b {
  text-align: center;
  font-size: 7vw;
  position: relative;
  color: #636363;
}
</style>