<template>
  <div class="screen">
    <div
      ref="cnt"
      id="cnt"
      class="cnt"
      v-show="$store.state.loadNum >= 1"
    ></div>
  </div>
</template>
<script>
import * as THREE from "three";
import scene from "@/three/scene";
import renderer from "@/three/renderer";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import Map from "@/three/setModel/map";
import Car from "@/three/setModel/car";
import StreetLamp from "@/three/setModel/streetLamp";
import StreetLampList from "@/three/setModel/streetLampList";
export default {
  name: "",
  data() {
    return {
      cntBol: false,
      clock: null,
      mouse: { x: 0, y: 0 },
      camera: "",
      controls: "",
    };
  },
  methods: {
    init() {
      this.camera = new THREE.PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      //设置相机位置 x,y,z
      this.camera.position.set(0, 1000, 1000);

      scene.add(this.camera);
      this.controls = new OrbitControls(this.camera, renderer.domElement);
      this.controls.enableDamping = true;
      this.controls.autoRotateSpeed = 1;

      // this.controls.minDistance = 0;
      // this.controls.maxDistance = 1800;
      // scene.add(axesHelper);
      document.getElementById("cnt").appendChild(renderer.domElement);
      Map();
      Car();
      StreetLampList.forEach((item) => {
        StreetLamp(
          item.name,
          item.id,
          item.positionX,
          item.positionY,
          item.positionZ
        );
        // Light(item.id, item.positionX, item.positionY, item.positionZ);
      });
      // const loading = this.$loading({
      //   lock: true,
      //   text: "加载中",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      this.clock = new THREE.Clock();
      this.render();
    },
    // 不断渲染
    render() {
      let deltaTime = this.clock.getDelta();
      this.controls.target.x +=
        (this.mouse.x * 200 - this.controls.target.x) * deltaTime;
      this.$store.state.loadNum = scene.children.length / 36;

      this.controls.update();
      renderer.render(scene, this.camera);
      // 不断渲染
      requestAnimationFrame(this.render);
    },
    // 画面变化
    resize() {
      // 监听鼠标移动事件
      window.addEventListener("mousemove", () => {
        this.mouse.x = event.clientX / window.innerWidth - 0.5;
        this.mouse.y = -(event.clientY / window.innerHeight - 0.5);
      });
    },
  },
  mounted() {
    this.init();
  },
  // beforeDestroy() {
  //   this.render = null;
  // },
};
</script>

<style scoped>
.screen {
  height: 80vh;
}
.cnt {
  /* position: relative; */
  /* top: 0; */
  width: 100%;
  height: 80vh;
  outline: none;

  overflow: hidden;
}
</style>

