<template>
  <div class="news">
    <img class="border" src="@/assets/img/swiper-border.jpg" alt="" />
    <div class="cnt">
      <div v-for="(item, index) in newsList" :key="index" @click="skip(item)">
        <div class="img-wp">
          <img :src="item.cover" alt="" />
        </div>
        <div class="right">
          <p class="essay" v-html="item.content"></p>
          <p class="time">{{ item.createTime }}</p>
        </div>
      </div>
    </div>
    <div class="nmore" @click="$router.push('/Pnews')">查看更多</div>
  </div>
</template>
    
<script>
import timeGet from "@/utils/timeGet.js";
export default {
  name: "",
  data() {
    return {
      newsList: [],
    };
  },
  methods: {
    skip(item) {
      this.$router.push({
        path: "/Pnews/info",
        query: {
          title: item.title,
          cnt: item.content,
          imgUrl: item.cover,
          time: item.createTime,
        },
      });
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/articles/page",
      params: {
        pageNo: 1,
        pageSize: 3,
      },
    }).then((res) => {
      console.log("其他新闻", res.data);
      this.newsList = res.data.data.list.reverse().map((item) => {
        item.createTime = timeGet(item.createTime);
        return item;
      });
    });
  },
};
</script>
    
<style scoped>
.news {
  position: relative;
  height: 75vh;
  background: #fff;
}
.border {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cnt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  position: relative;
  z-index: 10;
  padding-top: 5vw;
}
.cnt > div {
  width: 85%;
  height: 33.3%;
  padding: 5vw 0 5vw;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.cnt > div:nth-child(3) {
  border: none;
  padding: none;
}
.cnt div .img-wp {
  height: 100%;
  width: 45%;
}
.cnt div .right {
  width: 45%;
}
.cnt div .img-wp img {
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}
.essay {
  display: -webkit-box;
  height: 36px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
}
.time {
  font-size: 12px;
  opacity: 0.5;
  margin-top: 10px;
}
.nmore {
  width: 96%;
  height: 13vw;
  line-height: 13vw;
  position: absolute;
  bottom: 4%;
  left: 2%;
  background-image: linear-gradient(0deg, #01adeb47, rgba(61, 199, 160, 0));
  border-radius: 0px 0px 5vw 5vw;
  text-align: center;
  color: #01aeeb;
}
</style>