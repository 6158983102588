<template>
  <div>
    <!-- <div v-for="(item, index) in list" :key="index" @click="cut(index)">
      {{ item.title }}
    </div> -->
    <div class="nav">
      <div class="swiper" ref="swiperNav">
        <div class="swiper-container1">
          <div class="swiper-wrapper">
            <div
              :class="
                navIndex == index ? 'swiper-slide active' : 'swiper-slide'
              "
              v-for="(item, index) in list"
              :key="index"
              @click="cut(index)"
            >
              <div class="swiper-wp">
                {{ item.title }}
              </div>
            </div>
            <div
              class="nav-bg"
              :style="{
                transform: 'translate(' + pan + 'px,-50%',
              }"
            >
              <img src="@/assets/img/nav-bg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cnt">
      <div class="swiper">
        <img
          class="swiper-border"
          src="@/assets/img/swiper-border.jpg"
          alt=""
        />
        <div class="swiper-container" ref="swiperCnt">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="swiper-wp">
                <img :src="item.cover" alt="" />
                <div class="content">
                  <p>{{ item.title }}</p>
                  <p>→</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "swiper",
  data() {
    return {
      swiper1: "",
      swiper2: "",
      list: [],
      navIndex: 0,
      pan: 0,
    };
  },
  methods: {
    cut(index) {
      this.swiper1.slideTo(index, 1000, false);
      this.navIndex = index;
      this.pan = (this.$refs.swiperNav.offsetWidth / 4) * this.navIndex;
    },
    render() {
      this.navIndex = this.swiper1.activeIndex;
      this.pan =
        (this.$refs.swiperNav.offsetWidth / 4) * this.navIndex +
        (this.$refs.swiperNav.offsetWidth / 4) *
          ((-this.swiper1.translate -
            this.$refs.swiperCnt.offsetWidth * this.navIndex) /
            this.$refs.swiperCnt.offsetWidth);
      // 不断渲染
      requestAnimationFrame(this.render);
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/home/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        // title: "home",
      },
    }).then((res) => {
      console.log("主页", res.data);
      this.list = res.data.data.list;
      // this.imgList = res.data.data.list.reverse();
      setTimeout(() => {
        this.swiper1 = new this.swiper(".swiper-container", {
          speed: 700,
          effect: "cube",
          onTransitionEnd: (swiper) => {
            // console.log(swiper);
          },
        });
        this.swiper2 = new this.swiper(".swiper-container1", {
          speed: 700,
          slidesPerView: 4,
        });
      }, 500);
    });
  },
  mounted() {
    this.render();
  },
  watch: {},
};
</script>
    
<style scoped>
.cnt .swiper {
  position: relative;
  height: 80vh;
}
.cnt .swiper-border {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cnt .swiper-container {
  width: 93%;
  height: 92%;
  position: absolute;
  left: 3%;
  top: 2%;
}
.cnt .swiper-wrapper .swiper-slide {
  text-align: center;
  overflow: hidden;
}
.cnt .swiper-slide img {
  /* width: 100%; */
  height: 100%;
}
.cnt .swiper-wp {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.cnt .swiper-wp .content {
  width: 90%;
  padding: 20px 0;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px #fff solid;
}
.nav .swiper {
  margin: 0 0 20px;
  position: relative;
  height: 50px;
  border-radius: 30px;
  background: #fff;
  line-height: 50px;
  text-align: center;
  overflow: hidden;
}
.nav .swiper .nav-bg {
  position: absolute;
  top: 50%;
  width: 25%;
  height: 50px;
  transition: all 0.35s;
}
.nav .swiper .nav-bg img {
  width: 100%;
  height: 100%;
}
.active {
  color: #fff;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: all 0.35s;
  z-index: 5;
}
</style>
<style>
.cnt .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.cnt .swiper-pagination-bullet-active {
  background: #fff;
}
.cnt .swiper-container-3d .swiper-slide-shadow-left,
.cnt .swiper-container-3d .swiper-slide-shadow-right {
  background: none;
}
</style>